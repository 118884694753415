$(function () {

    void 0;
    
    /**
     *   Carregando Utilitários
     * */
    var $utility = new cemf.js.Utility();
    $utility.startWeb();

    $("#phone").mask("(00) 00000-0000");

});